body {
  margin: 0 auto;
  /* background-color: #fff; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 4rem; */
}


h1 {
  text-align: center;
  font-family: 'Indie Flower', cursive;
  font-size: 4rem;
}
h2 {
  text-align: center;
  font-family: 'Indie Flower', cursive;
  font-size: 1rem;
}
h4 {
  font-size: 2.5rem;
  padding-bottom: 10px;
}
p {
  text-align: center;
  font-family: 'Indie Flower', cursive;
  font-size: 1rem;
  line-height: 1.5;
  /* font-size: 1.6rem; */
  padding-bottom: 10px;
}
img {
  max-width: 100%;
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
